import React from 'react'
import styles from './scroll-indicator.module.scss'

class ScrollIndicator extends React.Component {
  render() {
    return (
      <div>
        <div className={styles.mouse}></div>
        <div>
          <span className={styles.eins}></span>
          <span className={styles.zwei}></span>
          <span className={styles.drei}></span>
        </div>
      </div>
    )
  }
}

export default ScrollIndicator
