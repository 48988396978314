import styled from '@emotion/styled';
import React from 'react';

import Logo from '../assets/images/logo.inline.svg';
import Scroll from './Scroll';
import ScrollIndicator from './scroll-indicator';

const LogoWrapper = styled(Logo)`
  width: 200px;
  height: 200px;
  margin: 3em !important;
`

const HeaderText = styled.div`
  color: black;

  h1 {
    color: black;
  }
`

const Header = props => (
  <header id="header" className="alt">
    <LogoWrapper></LogoWrapper>
    <HeaderText className="text alt">
      <h1>Willkommen bei Team Barashka</h1>
      <p>
        Hier trifft Erfahrung auf Innovation in einer perfekten Kombination.
      </p>
      <br />
      <Scroll type="id" element="intro">
        <a href="#">
          <ScrollIndicator />
        </a>
      </Scroll>
    </HeaderText>
  </header>
)

export default Header
