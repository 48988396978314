import { graphql, Link, StaticQuery } from 'gatsby'
import React from 'react'
import { Accordion, Toggle } from './Accordion'

class Leistungen extends React.Component {
  render() {
    return (
      <StaticQuery
        // TODO use this query
        query={graphql`
          query {
            allMarkdownRemark(
              filter: { frontmatter: { tags: { in: "leistung" } } }
            ) {
              edges {
                node {
                  html
                }
              }
            }
          }
        `}
        render={data => (
          <section className="main special">
            <header className="major">
              <h2>Unsere Leistungen</h2>
            </header>
            <Accordion>
              <Toggle title="Zahnvorsorge">
                <ul>
                  <li>
                    <Link to="professsionelle-zahnreinigung">
                      Professionelle Zahnreinigung
                    </Link>
                  </li>
                  <li>
                    <Link to="Zahnversiegelung">
                      Zahnversiegelung
                    </Link>
                  </li>    
                    <Link to="Knirscherschienen">
                      Knirscherschienen
                    </Link>          
                </ul>
              </Toggle>
              <Toggle title="Ästhetische Zahnheilkunde">
                <ul>
                  <li>
                      <Link to="Bleaching">
                        Bleaching
                      </Link>
                  </li> 
                  <Link to="Veneers">
                      Veneers
                    </Link>   
                </ul> 
              </Toggle>
              <Toggle title="Konservierende Behandlung">
              <ul>
                  <li>
                    <Link to="Kunststofffüllungen">
                      Kunststofffüllungen
                    </Link>
                  </li>  
                  <li>
                    <Link to="Keramikinlays">
                      Keramikinlays
                    </Link>   
                    </li>  
                    <Link to="Wurzelbehandlung">
                      Wurzelbehandlung
                    </Link>   
              </ul> 
              </Toggle>
              <Toggle title="Parodontosebehandlung">
                <ul>
                <li>
                    <Link to="Basisbehandlung">
                      Die Behandlung
                    </Link>
                </li> 
                </ul>
              </Toggle>
              <Toggle title="Prothetische Behandlung">
                <ul>
                <li>
                    <Link to="kronenUndBruken">
                    Kronen/Brücken
                    </Link>
                </li> 
                  <Link to="Prothesen">
                    Teil-/Vollprothesen
                    </Link>
                </ul>
              </Toggle>
              <Toggle title="Implantate">
              <ul>
                <li>
                    <Link to="Implantate">
                    Dentale Implantate
                    </Link>
                </li> 
                </ul>
              </Toggle>
              <Toggle title="Harmonieschiene"></Toggle>
            </Accordion>
          </section>
        )}
      />
    )
  }
}

export default Leistungen
