import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Waypoint from 'react-waypoint'

import Leistungen from '../components/leistungen'
import Layout from '../components/layout'
import Header from '../components/Header'
import Nav from '../components/Nav'
// import AOS from 'aos';
import 'aos/dist/aos.css'
import Map from '../components/Map';
import Footer from '../components/Footer';

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stickyNav: true
        }
    }

    componentDidMount() {
        const isBrowser = typeof window !== 'undefined';
        const AOS = isBrowser ? require('aos') : undefined;

        this.aos = AOS;
        this.aos.init();
    }


    _handleWaypointEnter = (val) => {
        this.setState(() => ({ stickyNav: false }));
    }

    _handleWaypointLeave = () => {
        this.setState(() => ({ stickyNav: true }));
    }

    render() {

        return (

            <Layout>
                <Helmet title="Zahnarztpraxis Dr. med. dent. Ivanka Barashka" />

                <Nav sticky={this.state.stickyNav} />
                <Waypoint
                    onEnter={this._handleWaypointEnter}
                    onLeave={this._handleWaypointLeave}
                    scrollableAncestor={'window'}
                />

                <Header />

                <div id="main" className="wrapper">
                    <section id="intro" className="main">
                        <div className="spotlight">
                            <div className="content">
                                <header
                                    data-aos="fade-right"
                                    data-aos-offset="0"
                                    data-aos-delay="0"
                                    data-aos-duration="400"
                                    data-aos-easing="ease-in-out"
                                    data-aos-mirror="true"
                                    data-aos-once="true"
                                    data-aos-anchor-placement="top-bottom"
                                    className="major">
                                    <h2>Unsere Praxis</h2>
                                </header>
                                <div
                                    data-aos="fade-up"
                                    data-aos-offset="-400"
                                    data-aos-delay="0"
                                    data-aos-duration="300"
                                    data-aos-easing="ease-in-out"
                                    data-aos-mirror="true"
                                    data-aos-once="true"
                                    data-aos-anchor-placement="top-center"
                                >
                                    <p>

                                        Am 1. Oktober 2003 wurde unsere Praxis gegründet. Seit diesem Zeitpunkt bemühen wir uns allen Patienten so schnell wie möglich zu helfen und mit den besten und innovativsten Methoden zu versorgen.
                                        </p>


                                    <p>
                                        Unsere Zahnarztpraxis ist auf schmerzlose und einfühlsame Behandlungen, besonders von Angstpatienten spezialisiert sodass alle Patienten sich bei uns wohl fühlen und versuchen können sich zu entspannen. Wir legen viel Wert auf hochwertige Zahnästhetik. Seit unserer Eröffnung liefern wir nur Top-Qualität auf den Gebieten Zahnästhetik, Zahnvorsorge und Zahnerhalt sowie Implantologie und natürlich Prothetik, insbesondere vollkeramischer Zahnersatz.
                                        </p>

                                    <p>
                                        In unserer Praxis ist es selbstverständlich, dass auch Kinder spielerisch, schmerz- und angstfrei behandelt werden.
                                        Wir sprechen auch Englisch, Russisch und Bulgarisch!
                                        </p>
                                </div>

                            </div>
                        </div>
                    </section>

                    <div id="first"
                        data-aos="fade-up"
                        data-aos-offset="-300"
                        data-aos-delay="0"
                        data-aos-duration="400"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        data-aos-once="true"
                        data-aos-anchor-placement="top-center"
                    >
                        <Leistungen />
                    </div>

                    <section id="kontakt" className="wrapper">
                            <Footer></Footer>
                    </section>


                </div>

            </Layout>
        )
    }
}


const query = graphql`
  query Leistungen {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            title
          }
        }
      }
    }
  }
`

export default Index
